import React from "react";
import GATSBY_COMPILED_MDX from "/opt/render/project/src/packages/website/content/standalone/bubble.mdx";
import {graphql} from 'gatsby';
import SEO from '../components/SEO';
const Standalone = ({data, children}) => children;
export const query = graphql`
  query ($id: String) {
    site {
      siteMetadata {
        cdn
        version
      }
    }
    mdx(id: { eq: $id }) {
      fields {
        permalink
      }
      frontmatter {
        title
      }
    }
  }
`;
export const Head = ({data}) => React.createElement(React.Fragment, null, React.createElement(SEO, {
  title: data.mdx.frontmatter.title,
  permalink: data.mdx.fields.permalink
}), React.createElement("link", {
  rel: "stylesheet",
  href: `${data.site.siteMetadata.cdn}${data.site.siteMetadata.version}/quill.core.css`
}));
Standalone
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Standalone, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
